<template>
  <div class="main-report-table">
    <default-table :columns="tableColumns" :rows="tableRows">
      <template #header-cell="{ props }">
        <q-th :style="props.col.style">
          <template v-if="props.col.name === 'summary'">
            <question-btn
              class="q-mx-auto"
              theme="light"
              :on-click="() => appAlert(props.col.label)"
            />
          </template>
          <template v-else>{{ props.col.label }}</template>
        </q-th>
      </template>
      <template #body-cell="{ props }">
        <cell-filled :value="props.value" />
      </template>
      <template #body-cell-name="{ props }">
        <q-td>
          <div class="cell">
            <div class="title">
              {{ props.value }}
            </div>
          </div>
        </q-td>
      </template>
      <template #body-cell-description="{ props }">
        <q-td>
          <div class="cell">
            <question-btn :on-click="() => appAlert(props.value)" />
          </div>
        </q-td>
      </template>
    </default-table>
  </div>
</template>

<script setup>
import DefaultTable from "@/components/table/DefaultTable";
import { formatNumber } from "@/helpers/formatNumber";
import { getHikeEndRateForMoneyCode } from "@/helpers/reports/getHikeEndRateForMoneyCode";
import { computed } from "vue";
import { store } from "@/store/store";
import { appAlert } from "@/components/appAlert/appAlert";
import CellFilled from "@/components/table/cellFilled/cellFilled.vue";
import QuestionBtn from "@/components/questionBtn/QuestionBtn.vue";

const hasSummaryColumn =
  store.currentReport.balance &&
  (store.currentReport.balance.length > 1 ||
    store.currentReport.balance[0].name !== "RUB");
const rateOnHikeEnd = {};
await Promise.all(
  store.currentReport.moneyCodes.map(async (code) => {
    rateOnHikeEnd[code] = await getHikeEndRateForMoneyCode(
      store.currentReport,
      code
    );
  })
);

const tableColumns = computed(() => {
  if (!store.currentReport.balance) {
    return [];
  }

  const columns = [
    {
      align: "center",
      field: "description",
      name: "description",
      style: "min-width: 25px; width: 40px; white-space: break-spaces",
    },
    {
      field: "name",
      name: "name",
      style:
        "min-width: 100px; width: calc(50% - 40px); white-space: break-spaces",
    },
  ];

  let columnDivider =
    store.currentReport.balance.length >= 3
      ? 3
      : store.currentReport.balance.length;
  if (hasSummaryColumn && columnDivider < 3) {
    columnDivider = columnDivider++;
  }
  const moneyCodeWidth = 50 / columnDivider;

  store.currentReport.balance.forEach(({ moneyCode }) => {
    columns.push({
      name: moneyCode,
      field: moneyCode,
      label: moneyCode,
      style: `min-width: 30px; width: ${moneyCodeWidth}%; white-space: break-spaces`,
    });
  });
  if (hasSummaryColumn) {
    columns.push({
      field: "summary",
      name: "summary",
      label: "Сумма в рублях на последний день похода",
      style: `min-width: 30px; width: ${moneyCodeWidth}%; white-space: break-spaces;`,
    });
    store.currentReport.balanceSummary = {};
  }
  return columns;
});

// const moneyCodeCells = computed(() => {
//   return store.currentReport.balance.map(({ moneyCode }) => {
//     return `body-cell-${moneyCode}`;
//   });
// });

const tableRows = computed(() => {
  const rows = [
    {
      name: "Всего получено",
      fieldName: "revenue",
      description:
        "Общая сумма оплат участников* + получено от инструкторов/кураторов/офиса<br><br>* Сумма уже включает оплаты с исключениями",
    },
    {
      name: "Всего расходов",
      fieldName: "expenses",
      description: "Общая сумма расходов по всем категориям",
    },
    {
      name: "Всего по прибыли инструктора(ов)",
      fieldName: "instructorsRevenue",
      description: "Общая сумма доходов всех инструкторов",
    },
    {
      name: "Итого остаток на руках",
      fieldName: "resultWithIncomingPayments",
      description:
        "Общая сумма оплат участников* + получено от инструкторов/кураторов/офиса - расходы - доходы инструктора, и все это с учетом конвертации",
    },
    {
      name: "Итого остаток на руках после передач",
      fieldName: "finalResult",
      description:
        "Итого остаток на руках - минусуем передано инструкторам/кураторам/офису",
    },
  ];

  return rows.map((row) => {
    const processedRow = { ...row };
    let summary = 0;
    let hasMissingRates = false;

    // Process each balance item
    for (const balanceItem of store.currentReport.balance) {
      const moneyCode = balanceItem.moneyCode;
      if (!moneyCode) {
        continue;
      }
      const rawValue = balanceItem[row.fieldName];

      // Store the raw value in the row
      processedRow[moneyCode] = rawValue;

      // Skip summary calculation if not needed
      if (!hasSummaryColumn) continue;
      // Skip if we already have missing rates
      if (hasMissingRates) continue;

      // Convert to number (remove spaces and parse)
      const numericValue = Number(rawValue.replace(/\s/g, "")) || 0;

      if (moneyCode === "RUB") {
        summary += numericValue;
      } else {
        const rate = rateOnHikeEnd[moneyCode];

        if (rate === undefined) {
          hasMissingRates = true;
          summary = `${moneyCode} - нет курса`;
          continue;
        }

        summary += numericValue * rate;
      }
    }

    if (hasSummaryColumn) {
      processedRow.summary = hasMissingRates ? summary : formatNumber(summary);
      store.currentReport.balanceSummary[row.fieldName] = processedRow.summary;
    }

    return processedRow;
  });
});
</script>

<style>
.main-report-table {
  overflow-x: auto;
  max-width: 100%;
  margin-top: 20px;
}

.main-report-table .cell {
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  white-space: break-spaces;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
}

.main-report-table .title {
  font-weight: bold;
  color: grey;
}
</style>
