import script from "./CurrentReportTable.vue?vue&type=script&setup=true&lang=js"
export * from "./CurrentReportTable.vue?vue&type=script&setup=true&lang=js"

import "./CurrentReportTable.vue?vue&type=style&index=0&id=5e317572&lang=css"

const __exports__ = script;

export default __exports__
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTh,QTd});
