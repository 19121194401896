import { formatRatesXMLtoMap } from "./formatRatesXMLtoMap";
import { cachedRequestRatesXML } from "@/api/cachedRequests";

export const getMoneyCodeRateFromRatesXML = async (code, day, month, year) => {
  try {
    const ratesXML = await cachedRequestRatesXML(day, month, year);
    if (!ratesXML) {
      return undefined;
    }

    const rates = formatRatesXMLtoMap(ratesXML);

    if (!rates[code]) {
      return undefined;
    }

    const { value, nominal } = rates[code];

    return (value / nominal).toFixed(4);
  } catch (error) {
    return undefined;
  }
};
