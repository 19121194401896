import {
  createHeaderCell,
  headerCellColor,
} from "@/helpers/excel/helpers/createHeaderCell";
import { styleSheet } from "@/helpers/excel/helpers";

export function setConversions(sheet, conversions) {
  const rowStart = sheet.rowCount + 1;
  let rowIndex = rowStart;

  createHeaderCell(sheet.getCell(rowIndex, 1), "Конвертация");
  sheet.mergeCells(rowIndex, 2, rowIndex, 3);
  createHeaderCell(sheet.getCell(rowIndex, 2), "Из");
  sheet.mergeCells(rowIndex, 4, rowIndex, 5);
  createHeaderCell(sheet.getCell(rowIndex, 4), "В");
  createHeaderCell(sheet.getCell(rowIndex, 6), "Курс");
  createHeaderCell(
    sheet.getCell(rowIndex, 7),
    "Комментарий",
    undefined,
    headerCellColor.hint
  );
  createHeaderCell(
    sheet.getCell(rowIndex, 8),
    "Дата",
    undefined,
    headerCellColor.hint
  );

  rowIndex += 1;

  for (let conversion of conversions) {
    sheet.getCell(rowIndex, 2).value = conversion.from.sum;
    sheet.getCell(rowIndex, 3).value = conversion.from.moneyCode;
    sheet.getCell(rowIndex, 4).value = conversion.to.sum;
    sheet.getCell(rowIndex, 5).value = conversion.to.moneyCode;
    sheet.getCell(rowIndex, 6).value = (
      conversion.to.sum / conversion.from.sum
    ).toFixed(5);
    sheet.getCell(rowIndex, 7).value = conversion.comment;
    sheet.getCell(rowIndex, 8).value = conversion.date;

    rowIndex += 1;
  }

  styleSheet(sheet, rowStart, 8);
}
