<template>
  <div class="toggle">
    <div>Сгруппировать по категориям</div>
    <q-toggle v-model="data.isGrouped" color="orange" />
  </div>
  <expense-table
    v-if="!data.isGrouped"
    :rows="expensesRows"
    :columns="expensesColumns"
    :edit="edit"
    :remove="remove"
  />
  <expense-table
    v-if="data.isGrouped"
    :rows="groupedExpensesRows"
    :columns="groupedExpensesColumns"
    :edit="edit"
    :remove="remove"
  />
  <button-add :handler="onExpenseAdd" />
  <!--  <payments-information-->
  <!--    :payments="-->
  <!--      store.currentReport.outgoingPayments.filter(-->
  <!--        (payment) => payment.label === 'Доходы инструктора'-->
  <!--      )-->
  <!--    "-->
  <!--    :money-codes="['RUB']"-->
  <!--  />-->
</template>

<script setup>
import ButtonAdd from "@/components/buttonAdd/ButtonAdd";
import { openModalPage } from "@/modalPages/helpers/openModalPage";
import { modalName } from "@/modalPages/helpers/modalName";
import { store } from "@/store/store";
import { expensesColumns } from "@/components/expenseTable/columns/expensesColumns";
import { reactive, computed } from "vue";
import { groupedExpensesColumns } from "@/components/expenseTable/columns/groupedExpensesColumns";
import { groupExpenses } from "@/helpers/reports/groupExpenses";
import { formatNumber } from "@/helpers/formatNumber";
import ExpenseTable from "@/components/expenseTable/ExpenseTable";
import TextHeader from "@/components/textHeader/TextHeader.vue";
import PaymentsTable from "@/components/paymentsTable/PaymentsTable.vue";
import { paymentsColumns } from "@/components/paymentsTable/paymentsColumns";

const groupedExpenses = computed(() => {
  return groupExpenses(store.currentReport.expenses);
});

const data = reactive({
  isGrouped: false,
});

const remove = (index) => {
  store.currentReport.expenses.splice(index, 1);
};

const groupedExpensesRows = computed(() => {
  return groupedExpenses.value?.map((expense) => {
    if (expense.isUncountable) {
      return {
        ...expense,
        sum: "-",
      };
    }

    return {
      ...expense,
      sum: `${formatNumber(expense.sum)} ${expense.moneyCode}`,
    };
  });
});
const expensesRows = computed(() => {
  return store.currentReport?.expenses.map((expense) => ({
    ...expense,
    sum: `${formatNumber(expense.sum)} ${expense.moneyCode}`,
  }));
});

const onExpenseAdd = () => {
  openModalPage(modalName.modalExpense, {
    saveData: (expense) => store.currentReport.expenses.push(expense),
    moneyCodes: store.currentReport.moneyCodes,
  });
};

const edit = (index) => {
  openModalPage(modalName.modalExpense, {
    saveData: (expense) => (store.currentReport.expenses[index] = expense),
    expense: store.currentReport.expenses[index],
    moneyCodes: store.currentReport.moneyCodes,
    header: "Редактировать расход",
  });
};

const instructorsIncome = computed(() => {
  return store.currentReport.outgoingPayments.find(
    (payment) => payment.label === "Доходы инструктора"
  );
});

const addInstructorIncome = () => {
  openModalPage(modalName.modalInstructorsIncome, {
    type: instructorsIncome.value.type,
    saveData: (_payment) => instructorsIncome.value.payments.push(_payment),
    moneyCodes: ["RUB"],
    header: "Добавить доход инструктора",
  });
};

const getInstructorIncomeRows = () => {
  const countSum = (payment) => {
    return (
      Number(payment.sum) +
      Number(payment.bonusSum || 0) +
      Number(payment.soldItemsSum || 0) +
      Number(payment.attestationSum || 0)
    );
  };

  if (!instructorsIncome.value.payments) {
    return [];
  }

  return instructorsIncome.value.payments.map((targetPayment) => ({
    ...targetPayment,
    sum: `${formatNumber(countSum(targetPayment))} ${targetPayment.moneyCode}`,
    income: `${formatNumber(targetPayment.sum)} ${targetPayment.moneyCode}`,
    bonusSum: `${formatNumber(targetPayment.bonusSum || 0)} ${
      targetPayment.moneyCode
    }`,
    soldItemsSum: `${formatNumber(targetPayment.soldItemsSum || 0)} ${
      targetPayment.moneyCode
    }`,
    attestationSum: `${formatNumber(targetPayment.attestationSum || 0)} ${
      targetPayment.moneyCode
    }`,
  }));
};

const removeInstructorIncome = (payment, index) => {
  payment.payments.splice(index, 1);
};

const editInstructorIncome = (payment, index) => {
  openModalPage(modalName.modalInstructorsIncome, {
    saveData: (newPayment) => {
      payment.payments[index] = newPayment;
    },
    payment: payment.payments[index],
    moneyCodes: ["RUB"],
    type: payment.type,
    header: "Редактировать доход инструктора",
  });
};

const instructorRowNames = {
  name: "ФИО",
  income: "Сумма основной части дохода",
  bonusSum: "Сумма бонусной части дохода (не обязательно)",
  soldItemsSum: "Бонус за покупку раскладки (не обязательно)",
  attestationSum: "Надбавка за аттестацию (не обязательно)",
  comment: "Комментарий",
  date: "Дата",
};
</script>

<style scoped>
.toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
