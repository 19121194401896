export const getRates = () => {
  return {
    RUB: {
      charCode: "RUB",
      name: "Российский рубль",
    },
    JPY: {
      numCode: "392",
      charCode: "JPY",
      name: "Японских иен",
    },
    AUD: {
      numCode: "036",
      charCode: "AUD",
      name: "Австралийский доллар",
    },
    NPR: {
      name: "Непальских рупий",
      numCode: "524",
      charCode: "NPR",
    },
    AZN: {
      numCode: "944",
      charCode: "AZN",
      name: "Азербайджанский манат",
    },
    GBP: {
      numCode: "826",
      charCode: "GBP",
      name: "Фунт стерлингов Соединенного королевства",
    },
    AMD: {
      numCode: "051",
      charCode: "AMD",
      name: "Армянских драмов",
    },
    BYN: {
      numCode: "933",
      charCode: "BYN",
      name: "Белорусский рубль",
    },
    BGN: {
      numCode: "975",
      charCode: "BGN",
      name: "Болгарский лев",
    },
    BRL: {
      numCode: "986",
      charCode: "BRL",
      name: "Бразильский реал",
    },
    HUF: {
      numCode: "348",
      charCode: "HUF",
      name: "Венгерских форинтов",
    },
    VND: {
      numCode: "704",
      charCode: "VND",
      name: "Вьетнамских донгов",
    },
    HKD: {
      numCode: "344",
      charCode: "HKD",
      name: "Гонконгских долларов",
    },
    GEL: {
      numCode: "981",
      charCode: "GEL",
      name: "Грузинский лари",
    },
    DKK: {
      numCode: "208",
      charCode: "DKK",
      name: "Датских крон",
    },
    AED: {
      numCode: "784",
      charCode: "AED",
      name: "Дирхам ОАЭ",
    },
    USD: {
      numCode: "840",
      charCode: "USD",
      name: "Доллар США",
    },
    EUR: {
      numCode: "978",
      charCode: "EUR",
      name: "Евро",
    },
    EGP: {
      numCode: "818",
      charCode: "EGP",
      name: "Египетских фунтов",
    },
    INR: {
      numCode: "356",
      charCode: "INR",
      name: "Индийских рупий",
    },
    IDR: {
      numCode: "360",
      charCode: "IDR",
      name: "Индонезийских рупий",
    },
    KZT: {
      numCode: "398",
      charCode: "KZT",
      name: "Казахстанских тенге",
    },
    CAD: {
      numCode: "124",
      charCode: "CAD",
      name: "Канадский доллар",
    },
    QAR: {
      numCode: "634",
      charCode: "QAR",
      name: "Катарский риал",
    },
    KGS: {
      numCode: "417",
      charCode: "KGS",
      name: "Киргизских сомов",
    },
    CNY: {
      numCode: "156",
      charCode: "CNY",
      name: "Китайских юаней",
    },
    MDL: {
      numCode: "498",
      charCode: "MDL",
      name: "Молдавских леев",
    },
    NZD: {
      numCode: "554",
      charCode: "NZD",
      name: "Новозеландский доллар",
    },
    NOK: {
      numCode: "578",
      charCode: "NOK",
      name: "Норвежских крон",
    },
    PLN: {
      numCode: "985",
      charCode: "PLN",
      name: "Польских злотых",
    },
    RON: {
      numCode: "946",
      charCode: "RON",
      name: "Румынских леев",
    },
    XDR: {
      numCode: "960",
      charCode: "XDR",
      name: "СДР (специальные права заимствования)",
    },
    SGD: {
      numCode: "702",
      charCode: "SGD",
      name: "Сингапурский доллар",
    },
    TJS: {
      numCode: "972",
      charCode: "TJS",
      name: "Таджикских сомони",
    },
    THB: {
      numCode: "764",
      charCode: "THB",
      name: "Таиландских батов",
    },
    TRY: {
      numCode: "949",
      charCode: "TRY",
      name: "Турецких лир",
    },
    TMT: {
      numCode: "934",
      charCode: "TMT",
      name: "Новый туркменский манат",
    },
    UZS: {
      numCode: "860",
      charCode: "UZS",
      name: "Узбекских сумов",
    },
    UAH: {
      numCode: "980",
      charCode: "UAH",
      name: "Украинских гривен",
    },
    CZK: {
      numCode: "203",
      charCode: "CZK",
      name: "Чешских крон",
    },
    SEK: {
      numCode: "752",
      charCode: "SEK",
      name: "Шведских крон",
    },
    CHF: {
      numCode: "756",
      charCode: "CHF",
      name: "Швейцарский франк",
    },
    RSD: {
      numCode: "941",
      charCode: "RSD",
      name: "Сербских динаров",
    },
    ZAR: {
      numCode: "710",
      charCode: "ZAR",
      name: "Южноафриканских рэндов",
    },
    KRW: {
      numCode: "410",
      charCode: "KRW",
      name: "Вон Республики Корея",
    },
    LVL: {
      numCode: "428",
      charCode: "LVL",
      name: "Латвийский лат",
    },
    LTL: {
      numCode: "440",
      charCode: "LTL",
      name: "Литовский лит",
    },
    EEK: {
      numCode: "233",
      charCode: "EEK",
      name: "Эстонских крон",
    },
    ATS: {
      numCode: "040",
      charCode: "ATS",
      name: "Австрийских шиллингов",
    },
    BEF: {
      numCode: "056",
      charCode: "BEF",
      name: "Бельгийских франков",
    },
    GRD: {
      numCode: "300",
      charCode: "GRD",
      name: "Греческих драхм",
    },
    IEP: {
      numCode: "372",
      charCode: "IEP",
      name: "Ирландский фунт",
    },
    ISK: {
      numCode: "352",
      charCode: "ISK",
      name: "Исландских крон",
    },
    ESP: {
      numCode: "724",
      charCode: "ESP",
      name: "Испанских песет",
    },
    ITL: {
      numCode: "380",
      charCode: "ITL",
      name: "Итальянских лир",
    },
    DEM: {
      numCode: "276",
      charCode: "DEM",
      name: "Немецкая марка",
    },
    NLG: {
      numCode: "528",
      charCode: "NLG",
      name: "Нидерландский гульден",
    },
    PTE: {
      numCode: "620",
      charCode: "PTE",
      name: "Португальских эскудо",
    },
    FIM: {
      numCode: "246",
      charCode: "FIM",
      name: "Финляндских марок",
    },
    FRF: {
      numCode: "250",
      charCode: "FRF",
      name: "Французских франков",
    },
    ARS: {
      numCode: "32",
      charCode: "ARS",
      name: "Аргентинских песо",
    },
    CLP: {
      numCode: "152",
      charCode: "CLP",
      name: "Чилийских песо",
    },
    PHP: {
      numCode: "608",
      charCode: "PHP",
      name: "Филиппинских песо",
    },
    LKR: {
      numCode: "144",
      charCode: "LKR",
      name: "Шри-ланкийских рупий",
    },
    TND: {
      numCode: "788",
      charCode: "TND",
      name: "Тунисских динаров",
    },
    MAD: {
      numCode: "504",
      charCode: "MAD",
      name: "Марокканских дирхамов",
    },
  };
};
