import { getMoneyCodeRateFromRatesXML } from "@/helpers/conversion/getMoneyCodeRateFromRatesXML";

export const getHikeEndRateForMoneyCode = async (currentReport, code) => {
  if (code === "RUB") {
    return 1;
  }
  const dates = currentReport.dates.split(" - ");
  const [day, month, year] = dates[1].split(".");
  const hikeEndDate = new Date(year, month - 1, day, 0, 0, 0, 0);
  const today = new Date().setHours(0, 0, 0, 0);
  if (hikeEndDate > today) {
    return undefined;
  }

  try {
    return await getMoneyCodeRateFromRatesXML(code, day, month, year);
  } catch (error) {
    return undefined;
  }
};
