import { getEmptyReport } from "@/helpers/reports/getEmptyReport";
import { getEmptyChecklist } from "@/helpers/reports/getEmptyChecklist";
import { countReportBalance } from "@/helpers/reports/countReportBalance";
import { paymentTableType } from "@/helpers/paymentTableType";

const getPayments = ({ sheet, paymentsLabel }) => {
  const payments = [];
  let paymentsStartRow = 0;

  for (let row = 1; row <= sheet.rowCount; row++) {
    const label = sheet.getCell(row, 1).value;

    if (
      label &&
      label.replaceAll(" ", "").toLowerCase() ===
        paymentsLabel.replaceAll(" ", "").toLowerCase()
    ) {
      paymentsStartRow = row + 1;
      break;
    }
  }

  if (paymentsStartRow === 0) {
    return;
  }

  while (true) {
    if (!sheet.getCell(paymentsStartRow, 4).value) {
      break;
    }

    const payment = {};

    if (
      sheet.getCell(paymentsStartRow, 2).value?.includes &&
      sheet.getCell(paymentsStartRow, 2).value.includes("]")
    ) {
      payment.name = sheet
        .getCell(paymentsStartRow, 2)
        .value.split("]")[1]
        .trim();
      payment.instructorId = Number(
        sheet
          .getCell(paymentsStartRow, 2)
          .value.split("]")[0]
          .replaceAll("[", "")
      );
    } else if (sheet.getCell(paymentsStartRow, 2).value) {
      payment.name = sheet.getCell(paymentsStartRow, 2).value;
    }
    payment.sum = Number(sheet.getCell(paymentsStartRow, 3).value);
    payment.moneyCode = sheet.getCell(paymentsStartRow, 4).value;
    payment.comment = sheet.getCell(paymentsStartRow, 5).value;
    payment.date = sheet.getCell(paymentsStartRow, 6).value;
    if (sheet.getCell(paymentsStartRow, 7).value) {
      payment.description = sheet.getCell(paymentsStartRow, 7).value;
    }

    if (isNaN(payment.sum)) {
      break;
    }

    payments.push(payment);
    paymentsStartRow++;
  }

  return payments;
};

const getInstructorsPayments = ({ sheet, paymentsLabel }) => {
  const payments = {};
  let paymentsStartRow = 0;

  for (let row = 1; row <= sheet.rowCount; row++) {
    const label = sheet.getCell(row, 1).value;

    if (
      label &&
      label.replaceAll(" ", "").toLowerCase() ===
        paymentsLabel.replaceAll(" ", "").toLowerCase()
    ) {
      paymentsStartRow = row + 1;
      break;
    }
  }

  if (paymentsStartRow === 0) {
    return;
  }

  while (true) {
    if (!sheet.getCell(paymentsStartRow, 4).value) {
      break;
    }

    let payment = {};

    if (
      sheet.getCell(paymentsStartRow, 2).value &&
      sheet.getCell(paymentsStartRow, 2).value.includes("]")
    ) {
      payment.name = sheet
        .getCell(paymentsStartRow, 2)
        .value.split("]")[1]
        .trim();
      payment.instructorId = Number(
        sheet
          .getCell(paymentsStartRow, 2)
          .value.split("]")[0]
          .replaceAll("[", "")
      );
    } else if (sheet.getCell(paymentsStartRow, 2).value) {
      payment.name = sheet.getCell(paymentsStartRow, 2).value;
    }
    payment.moneyCode = sheet.getCell(paymentsStartRow, 4).value;
    payment.comment = sheet.getCell(paymentsStartRow, 5).value;
    payment.date = sheet.getCell(paymentsStartRow, 6).value;

    if (payments[payment.instructorId]) {
      payment = {
        ...payments[payment.instructorId],
        ...payment,
      };
    }

    if (payment.comment.toLowerCase().includes("сумма основной части")) {
      payment.sum = Number(sheet.getCell(paymentsStartRow, 3).value);
    } else if (payment.comment.toLowerCase().includes("сумма бонусной части")) {
      payment.bonusSum = Number(sheet.getCell(paymentsStartRow, 3).value);
    } else if (
      payment.comment.toLowerCase().includes("бонус за покупку раскладки")
    ) {
      payment.soldItemsSum = Number(sheet.getCell(paymentsStartRow, 3).value);
    } else if (
      payment.comment.toLowerCase().includes("надбавка за аттестацию")
    ) {
      payment.attestationSum = Number(sheet.getCell(paymentsStartRow, 3).value);
    } else {
      break;
    }

    payments[payment.instructorId] = payment;
    paymentsStartRow++;
  }

  paymentsStartRow++;

  while (true) {
    if (!sheet.getCell(paymentsStartRow, 4).value) {
      break;
    }

    let payment = {};
    payment.instructorId = Number(
      sheet.getCell(paymentsStartRow, 2).value.split("]")[0].replaceAll("[", "")
    );
    payment.comment = sheet.getCell(paymentsStartRow, 5).value;

    if (payments[payment.instructorId]) {
      payments[payment.instructorId].comment = payment.comment;
    }
    paymentsStartRow++;
  }

  return Object.values(payments);
};

export const parseV2 = async (report) => {
  const sheet = await report.getWorksheet(1);

  const result = getEmptyReport();

  result.hikeId = sheet.getCell(2, 2).value;
  result.name = sheet.getCell(3, 2).value;
  result.dates = sheet.getCell(4, 2).value;
  result.members = sheet.getCell(6, 2).value;
  result.inactiveMembers = sheet.getCell(7, 2).value;

  let row = 11;
  const moneyCodes = [];

  for (let column = 3; column <= sheet.rowCount; column++) {
    const moneyCode = sheet.getCell(row, column).value;

    if (
      !moneyCode ||
      moneyCode.toLowerCase() === "сумма в рублях на последний день похода"
    ) {
      break;
    }

    moneyCodes.push(moneyCode);
  }

  result.moneyCodes = moneyCodes;

  const moneySums = {};
  const moneySumsSheet = await report.getWorksheet(2);
  let moneySumRow = 2;

  while (true) {
    const sum = moneySumsSheet.getCell(moneySumRow, 3).value;
    const moneyCode = moneySumsSheet.getCell(moneySumRow, 4).value;

    if (!moneyCode) {
      break;
    }

    moneySums[moneyCode] = sum;

    moneySumRow++;
  }

  result.moneySums = moneySums;

  const checklistSheet = await report.getWorksheet(
    report.worksheets.length === 4 ? 4 : 5
  );
  const checklist = getEmptyChecklist();

  for (let row = 1; row <= checklistSheet.rowCount; row++) {
    const header = checklistSheet.getCell(row, 1).value;
    const result = checklistSheet.getCell(row, 2).value;
    const comment = checklistSheet.getCell(row, 3).value;

    const targetChecklistIndex = checklist.findIndex((checklistItem) => {
      if (!checklistItem.header || !header) {
        return false;
      }

      return (
        checklistItem.header.replaceAll(" ", "").toLowerCase() ===
        header.replaceAll(" ", "").toLowerCase()
      );
    });

    if (targetChecklistIndex === -1) {
      continue;
    }

    checklist[targetChecklistIndex] = {
      ...checklist[targetChecklistIndex],
      header,
      result,
      comment,
    };
  }

  result.checklist = checklist;

  const conversions = [];
  const conversionSheet = await report.getWorksheet(3);
  let conversionStartRow = 0;

  for (let row = 1; row <= conversionSheet.rowCount; row++) {
    const label = conversionSheet.getCell(row, 1).value;

    if (label && label.replaceAll(" ", "").toLowerCase() === "конвертация") {
      conversionStartRow = row + 1;
      break;
    }
  }

  while (true) {
    if (!conversionSheet.getCell(conversionStartRow, 2).value) {
      break;
    }

    const from = {
      sum: Number(conversionSheet.getCell(conversionStartRow, 2).value),
      moneyCode: conversionSheet.getCell(conversionStartRow, 3).value,
    };
    const to = {
      sum: Number(conversionSheet.getCell(conversionStartRow, 4).value),
      moneyCode: conversionSheet.getCell(conversionStartRow, 5).value,
    };
    const comment = String(
      conversionSheet.getCell(conversionStartRow, 7).value
    );
    const date = conversionSheet.getCell(conversionStartRow, 8).value;

    conversions.push({
      from,
      to,
      comment,
      date,
    });

    conversionStartRow++;
  }

  result.conversions = conversions;

  const expenses = [];
  const expensesSheet = await report.getWorksheet(3);
  let expensesSheetStartRow = 0;

  for (let row = 1; row <= expensesSheet.rowCount; row++) {
    const label = expensesSheet.getCell(row, 1).value;

    if (label && label.replaceAll(" ", "").toLowerCase() === "расходы") {
      expensesSheetStartRow = row + 1;
      break;
    }
  }

  while (true) {
    const category = expensesSheet.getCell(expensesSheetStartRow, 2).value;
    const sum = expensesSheet.getCell(expensesSheetStartRow, 3).value;
    const moneyCode = expensesSheet.getCell(expensesSheetStartRow, 4).value;
    const comment = expensesSheet.getCell(expensesSheetStartRow, 5).value;
    const description = expensesSheet.getCell(expensesSheetStartRow, 6).value;
    const date = expensesSheet.getCell(expensesSheetStartRow, 7).value;

    if (sum === "сумма" || isNaN(Number(sum))) {
      break;
    }

    if (!moneyCode || !category) {
      break;
    }

    expenses.push({
      category,
      sum,
      moneyCode,
      comment,
      date,
      description,
    });

    expensesSheetStartRow++;
  }

  result.expenses = expenses;

  result.balance = countReportBalance(result);

  const incomingPaymentsSheet = await report.getWorksheet(2);

  result.incomingPayments = Array.from(
    result.incomingPayments.map((payment) => {
      return {
        ...payment,
        payments: getPayments({
          sheet: incomingPaymentsSheet,
          paymentsLabel: payment.label,
        }),
      };
    })
  );

  const mainSheet = await report.getWorksheet(1);
  const outgoingPaymentsSheet = await report.getWorksheet(
    report.worksheets.length === 4 ? 3 : 4
  );

  result.outgoingPayments = Array.from(
    result.outgoingPayments.map((payment) => {
      if (payment.type === paymentTableType.instructorsRevenue) {
        return {
          ...payment,
          payments: getInstructorsPayments({
            sheet: outgoingPaymentsSheet,
            paymentsLabel: payment.label,
          }),
        };
      }

      return {
        ...payment,
        payments: getPayments({
          sheet: mainSheet,
          paymentsLabel: payment.label,
        }),
      };
    })
  );

  return result;
};
