export const getTotalInstructorsRevenueForMoneyCode = (currentReport, code) => {
  let sum = 0;

  if (code !== "RUB") {
    return sum;
  }

  const { payments } = Array.isArray(currentReport)
    ? { payments: currentReport }
    : currentReport.outgoingPayments.find(
        (payment) => payment.label === "Доходы инструктора"
      );

  for (const payment of payments) {
    if (payment.moneyCode === code) {
      sum +=
        Number(payment.sum) +
        Number(payment.soldItemsSum || 0) +
        Number(payment.attestationSum || 0) +
        Number(payment.bonusSum || 0);
    }
  }

  return sum;
};
